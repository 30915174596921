import React from "react";
import Joi from "joi";
import Form from "./common/Form";
import FileDropzone from "./common/FileDropzone";
import { Button } from "reactstrap";
import { Modal } from "react-bootstrap";
import { noop } from "bootstrap/js/src/util";
import RESTApi from "../services/RESTApiService";

class LopeEdit extends Form {
  state = {
    // Stores the initial lope data before any update is performed
    initData: {},
    data: {
      lopeID: "",
      name: "",
      monthlyFundAmount: 0,
    },
    file: "",
    errors: {},
  };

  // Determine the state of the form
  async componentDidMount() {
    // Destructure the props from the component
    const { lopeToEdit, buttonLabel } = this.props;

    // If the buttonLabel is for add, do nothing and return
    if (buttonLabel === "Add Lope") return;

    // Else, get the lope information from the backend
    const {
      data: { lopeID, name, monthlyFundAmount },
    } = await RESTApi.get(`lope/${lopeToEdit}`);

    // Update the state with the lope in both initData and data so we can compare for updates
    this.setState({
      initData: { lopeID, name, monthlyFundAmount },
      data: { lopeID, name, monthlyFundAmount },
    });
  }

  schema = Joi.object({
    lopeID: Joi.any(),
    name: Joi.string().required().label("Name"),
    monthlyFundAmount: Joi.number().greater(-1).label("Monthly fund amount"),
    file: Joi.any(),
  });

  // Handles the submit button event
  doSubmit = async (lope) => {
    let result = null;
    const { initData, data, file } = this.state;

    // If the lopeID is blank, post the new lope, else do a put call
    if (lope.lopeID === "") {
      result = await this.props.onAddLope(lope);
      //console.log(status);
    } else if (
      // If we have any change in the lope, update it, else skip the update
      !(initData.name === data.name) ||
      !(initData.monthlyFundAmount === data.monthlyFundAmount)
    ) {
      // If we have a name or monthly fund amount change, handle it
      result = await this.props.onUpdateLope(lope);
      //console.log(status);
    } else result = { status: 200, lope: lope };

    // If we have a file to import and we have a result with a status of 200, upload it
    if (file && result && result.status === 200) {
      result = await this.props.onImportCSV(file, result.lope);
    }

    result && result.status === 200 ? this.props.onHide() : noop();
  };

  // Handles the onDrop event in react-dropzone
  handleOnDrop = (file) => {
    // console.log("file in handleOnDrop = ", file);

    // Update the state with the file
    this.setState((prevState) => ({
      file: file[0],
    }));
  };

  render() {
    const header = this.props.buttonLabel;

    return (
      <div className="container-fluid">
        <form onSubmit={this.handleSubmit} className="p-4">
          {this.renderInput("name", "Lope Name", "autoFocus")}
          {this.renderInput(
            "monthlyFundAmount",
            "Monthly Fund Amount",
            "",
            "number"
          )}
        </form>
        <FileDropzone onDrop={this.handleOnDrop} />
        <Modal.Footer>
          {this.renderButton(header === "Edit" ? "Save Changes" : "Add Lope")}
          <Button
            className="btn btn-primary mt-3"
            color="secondary"
            onClick={this.props.onHide}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </div>
    );
  }
}

export default LopeEdit;
