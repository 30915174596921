import axios from "axios";
import { toast } from "react-toastify";
import loggerService from "./LoggerService";
import authentication from "./authenticationService";

// The base URL for all axios requests
axios.defaults.baseURL = process.env.REACT_APP_API_URL;

// The default instance for Axios requests
const axiosInstance = axios.create({
  headers: { "Content-Type": "application/json" },
});

// The variable to track if token is currently being refreshed. Limits requests to refresh
let refreshInProg = null;

// Set the Authorization header for all axios requests
axiosInstance.interceptors.request.use((config) => {
  config.headers["Authorization"] = authentication.getToken();
  return config;
});

// The Axios interceptor to intercept ALL Axios responses to handle errors -- change null to variable to handle success if desired
axiosInstance.interceptors.response.use(null, async (error) => {
  // Get the current config
  const currentConfig = error.config;

  // If the error is expected handle it uniquely in the handler
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;

  // If we have a 401 Unauthorized error, and we aren't currently trying to log in, try to refresh the token
  if (
    currentConfig.url !== "login" &&
    error.response &&
    error.response.status === 401 &&
    !currentConfig._retry
  ) {
    currentConfig._retry = true;
    try {
      refreshInProg = refreshInProg
        ? refreshInProg
        : authentication.refreshToken();
      const refreshResponse = await refreshInProg;
      refreshInProg = null;

      // If we have data in the response handle the storage of the tokens
      if (refreshResponse.data) {
        const { access_token, refresh_token } = refreshResponse.data;
        // Store the tokens in the client's local storage
        localStorage.setItem("access_token", `Bearer ${access_token}`);
        localStorage.setItem("refresh_token", `Bearer ${refresh_token}`);
        return axiosInstance(currentConfig);
      }
    } catch (exception) {
      window.location = "/login";
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
      return Promise.reject(exception);
    }
  }

  // If the error is unexpected, log it and alert the user
  if (!expectedError) {
    // Log the error for resolution
    loggerService.log(error);
    toast.error("An unexpected error occurred.");
  }

  return Promise.reject(error);
});

export default {
  get: axiosInstance.get,
  put: axiosInstance.put,
  post: axiosInstance.post,
  delete: axiosInstance.delete,
};
