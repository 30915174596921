import { Modal } from "react-bootstrap";
import LopeEdit from "./LopeEdit";
import React from "react";
import TransactionEdit from "./TransactionEdit";
import FundsTransferModal from "./FundsTransferModal";

export default function MyModal(props) {
  const header = props.buttonLabel;

  return (
    <Modal
      onHide={props.onHide}
      show={props.show}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="modal-title">{header}</Modal.Title>
      </Modal.Header>
      {props.onAddLope && (
        <LopeEdit
          onHide={props.onHide}
          onAddLope={props.onAddLope}
          onUpdateLope={props.onUpdateLope}
          onImportCSV={props.onImportCSV}
          lopeToEdit={props.lopeToEdit}
          buttonLabel={props.buttonLabel}
        />
      )}
      {props.modalType === "TransactionEdit" && (
        <TransactionEdit
          onHide={props.onHide}
          onAddTransaction={props.onAddTransaction}
          onUpdateTransaction={props.onUpdateTransaction}
          transactionToEdit={props.transactionToEdit}
          buttonLabel={props.buttonLabel}
        />
      )}
      {props.modalType === "TransferFunds" && (
        <FundsTransferModal
          onHide={props.onHide}
          onTransferFunds={props.onTransferFunds}
          buttonLabel={props.buttonLabel}
          lopes={props.lopes}
          fromLope={props.fromLope}
        />
      )}
    </Modal>
  );
}
