export function toCurrency(amount) {
  let number = parseFloat(amount);

  return number.toLocaleString("USD", {
    style: "currency",
    currency: "USD",
    currencySign: "accounting",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}
