import React from "react";
import * as Utilities from "./common/Utilities";

export const LopeDetails = ({ lope }) => {
  return (
    <div className="card p-2 shadow">
      <div className="row">
        <h5 className="card-title col-auto me-auto">{lope.name}</h5>
        <h5 className="card-title col-auto ms-auto me-5">
          Balance: {Utilities.toCurrency(lope.balance)}
        </h5>
      </div>
      <h6 className="card-subtitle">
        Monthly Fund Amount: ${lope.monthlyFundAmount}
      </h6>
    </div>
  );
};
