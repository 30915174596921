import jwtDecode from "jwt-decode";
import axios from "axios";
import RESTApi from "./RESTApiService";

// Gets the currently logged-in user, or null if no user is logged in
export function getLoggedInUser() {
  try {
    let authToken = localStorage.getItem("access_token"); // Get the token
    authToken = authToken.substring(authToken.indexOf("Bearer ")); // Strip the "Bearer " from the front
    return jwtDecode(authToken); // Decode it
  } catch (exception) {
    console.log(
      "Exception in retrieving token in App componentDidMount",
      exception
    );
  }
}

export function getToken() {
  return localStorage.getItem("access_token");
}

export async function refreshToken() {
  // Grab the refresh token
  const refresh_token = localStorage.getItem("refresh_token");

  // Create a unique instance of Axios so we can set the header manually for this request
  const axiosTokenRefreshInstance = axios.create({
    headers: {
      Authorization: refresh_token,
    },
  });

  return await axiosTokenRefreshInstance.get(`token/refresh`);
}

// The login function for logging in a user and setting the localStorage tokens
export async function login(email, password) {
  const { data: tokens } = await RESTApi.post(`login`, {
    email,
    password,
  });

  const { access_token, refresh_token } = tokens;

  // Store the tokens in the client's local storage
  localStorage.setItem("access_token", `Bearer ${access_token}`);
  localStorage.setItem("refresh_token", `Bearer ${refresh_token}`);
}

// Logs out a user by clearing the localStorage of any keys
export function logout() {
  // Remove the tokens from the browser
  localStorage.clear();
}

export default {
  getLoggedInUser,
  getToken,
  refreshToken,
  login,
  logout,
};
