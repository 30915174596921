import { Button, Table } from "reactstrap";
import React from "react";
// Tell project that we use the styles.css sheet for this
import "./styles.css";

export default function MyTable({ tableInstance }) {
  // Destructure the incoming table instance
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    // page here instead of "rows"
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = tableInstance;

  const headerProps = (props, { column }) => getStyles(props, column.align);

  const cellProps = (props, { cell }) => getStyles(props, cell.column.align);

  const getStyles = (props, align = "left") => [
    props,
    {
      style: {
        justifyContent: align === "right" ? "flex-end" : "flex-start",
        alignItems: "flex-start",
        display: "flex",
      },
    },
  ];

  return (
    <div>
      <Table
        striped
        className="transactionTable table-sm flex-fill"
        {...getTableProps()}
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getSortByToggleProps()}
                  {...column.getHeaderProps(headerProps)}
                  className="pe-5"
                >
                  {column.render("Header")}
                  {column.canSort
                    ? column.isSorted
                      ? column.isSortedDesc
                        ? " ▼"
                        : " ▲"
                      : " ▲▼"
                    : ""}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell, index) => (
                  <td {...cell.getCellProps(cellProps)} className="pe-5">
                    {cell.render("Cell")}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </Table>
      {/*  Pagination implementation  */}
      <nav aria-label="list pages">
        <ul className="pagination">
          <li className="page-item">
            <Button
              className="btn page-link"
              disabled={!canPreviousPage}
              onClick={() => gotoPage(0)}
            >
              First
            </Button>
          </li>
          <li className="page-item">
            <Button
              className="btn page-link"
              disabled={!canPreviousPage}
              onClick={() => previousPage()}
            >
              Prev
            </Button>
          </li>
          <span className="d-flex align-items-center ps-2 pe-2">
            Page:&nbsp;
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>
          </span>
          <li className="page-item">
            <Button
              className="btn page-link"
              disabled={!canNextPage}
              onClick={() => nextPage()}
            >
              Next
            </Button>
          </li>
          <li className="page-item">
            <Button
              className="btn page-link"
              disabled={!canNextPage}
              onClick={() => gotoPage(pageCount - 1)}
            >
              Last
            </Button>
          </li>
          <div className="d-flex align-items-center ps-2">
            <span className="ps-2">
              Go to page:
              <input
                className="ms-2 me-2"
                type="number"
                defaultValue={pageIndex + 1}
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0;
                  gotoPage(page);
                }}
                style={{ width: "100px" }}
              />
            </span>{" "}
            <select
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
            >
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </div>
        </ul>
      </nav>
    </div>
  );
}
