import { useNavigate } from "react-router-dom";

// Allows navigation inside of class components
const withRouter = (Wrapper) => (props) => {
  const navigate = useNavigate();

  return <Wrapper {...props} {...{ navigate }} />;
};

export default withRouter;
