import React, { useState } from "react";
import { useAsyncDebounce } from "react-table";
import { Container, Input, InputGroup } from "reactstrap";

export function FilterBar({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  // Get the number of original rows
  const rowCount = preGlobalFilteredRows.length;
  const [value, setValue] = useState(globalFilter);

  // Wait until the user stops typing before searching
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 300); // 300 is the wait time

  // Render the FilterBar
  return (
    <Container className="SearchContainer">
      <InputGroup>
        <Input
          className="form-control"
          type="search"
          value={value || ""}
          onChange={(e) => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
          placeholder="Search"
          aria-label="Search"
        ></Input>
      </InputGroup>
    </Container>
  );
}
