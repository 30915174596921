import React, { Component } from "react";
import Joi from "joi";
import Input from "./Input";

class Form extends Component {
  state = {
    data: {},
    errors: {},
  };

  validate = () => {
    const options = { abortEarly: false };
    // Validate the user account data using the Joi Schema object
    const { error } = this.schema.validate(this.state.data, options);

    if (!error) return null;

    const errors = {};
    // TODO: replace this with map/reduce
    for (let item of error.details) {
      errors[item.path[0]] = item.message;
    }

    return errors;
  };

  validateProperty = ({ name, value }) => {
    // Create a prop object using computed properties to get the name of the property to validate and set its value to value
    let propToValidate = { [name]: value };
    // Create a new schema object containing only the property's required validation from the this.schema object
    let propSchema = Joi.object({ [name]: this.schema.extract(name) }).unknown(
      true
    );

    if (name.startsWith("confirm")) {
      // Grab the end of the string of any props that start with confirm_
      const propToMatch = name.substring(name.indexOf("_") + 1, name.size);

      // Add the matching prop to the propToValidate object
      propToValidate[propToMatch] = this.state.data[propToMatch];
    }
    // Validate the prop using a subsection of the schema
    const { error } = propSchema.validate(propToValidate);

    return error ? error.details[0].message : null;
  };

  handleSubmit = (e) => {
    // preventDefault prevents the form from submitting the form to the server causing a full page reload
    e.preventDefault();

    // Validate the form's data
    const errors = this.validate();
    this.setState({ errors: errors || {} });

    // Return if there are errors, do not submit
    if (errors) return;

    this.doSubmit(this.state.data);
  };

  // Handles all input changes by retrieving the event's name and updating the state
  handleChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    // Set the input.name property to the returned errorMessage
    if (errorMessage) errors[input.name] = errorMessage;
    // If the error is cleared, delete the data inside of errors for the property so the message is cleared
    else delete errors[input.name];

    const data = { ...this.state.data };
    data[input.name] = input.value;
    this.setState({ data, errors });
  };

  renderInput = (name, label, autoFocus, type = "text", disabled = false) => {
    // Destructure the data prop from state for use in HTML
    const { data, errors } = this.state;

    return (
      <Input
        autoFocus={autoFocus}
        type={type}
        name={name}
        value={data[name]}
        onChange={this.handleChange}
        label={label}
        error={errors[name]}
        disabled={disabled}
      />
    );
  };

  renderButton = (label, className) => {
    return (
      <button
        className={
          className
            ? "btn btn-primary mt-3 " + className
            : "btn btn-primary mt-3"
        }
        onClick={this.handleSubmit}
        disabled={this.validate()}
      >
        {label}
      </button>
    );
  };
}

export default Form;
