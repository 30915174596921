import React from "react";
import { Link, NavLink } from "react-router-dom";

const AppNavbar = ({ user }) => {
  return (
    <nav
      className="navbar navbar-expand-lg navbar-dark bg-dark"
      aria-label="navbar"
    >
      <div className="container-fluid">
        <Link className="navbar-brand" to="/lopes">
          DigiLopes
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarContent"
          aria-controls="navbarContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarContent">
          {user && (
            <React.Fragment>
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <NavLink className="nav-link" aria-current="page" to="/lopes">
                    Lopes
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to="/insights">
                    Insights
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to="/support">
                    Support
                  </NavLink>
                </li>
              </ul>

              {/*The DropDown menu*/}
              <ul className="navbar-nav ms-auto pe-3 mb-2 mb-lg-0">
                <li className="nav-item dropdown">
                  <button
                    className="btn nav-link dropdown-toggle"
                    id="navbarDropdown"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {user.firstName}
                  </button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdown"
                  >
                    <li>
                      <NavLink className="dropdown-item" to="/userProfile">
                        Profile
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="dropdown-item" to="/logout">
                        Logout
                      </NavLink>
                    </li>
                  </ul>
                </li>
                {/*End DropDown Menu*/}
              </ul>
            </React.Fragment>
          )}
          {/*ms-auto means margin-start auto, me-auto is margin-end auto*/}

          {!user && (
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <React.Fragment>
                <li className="nav-item">
                  <NavLink className="nav-link" to="/login">
                    Login
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to="/register">
                    Register
                  </NavLink>
                </li>
              </React.Fragment>
            </ul>
          )}
        </div>
      </div>
    </nav>
  );
};

export default AppNavbar;
