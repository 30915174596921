import { Component } from "react";
import authentication from "../services/authenticationService";

export default class Logout extends Component {
  componentDidMount() {
    authentication.logout();

    // Send the user to the login page
    window.location = "/login";
  }

  render() {
    return null;
  }
}
