import { Navigate, Outlet } from "react-router-dom";
import authenticationService from "../../services/authenticationService";

// Protects routes based on if a user is logged in and their roles specified in requiredPermissions
export const ProtectedRoute = ({
  requiredPermissions,
  redirectTo = "/login",
  children,
}) => {
  const user = authenticationService.getLoggedInUser();

  if (!user || !user.userRoles.includes(requiredPermissions)) {
    return <Navigate to={redirectTo} replace />;
  }
  return children ? children : <Outlet />;
};
