import React from "react";
import Form from "./common/Form";
import Joi from "joi";
import { toast } from "react-toastify";
import RESTApiService from "../services/RESTApiService";
import withRouter from "./common/withRouter";

class RegistrationForm extends Form {
  state = {
    data: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirm_password: "",
    },
    errors: {},
  };

  schema = Joi.object({
    firstName: Joi.string().trim().required().label("First name"),

    lastName: Joi.string().trim().required().label("Last name"),

    email: Joi.string()
      // TODO: get a list of TLD's and replace the explicit entries here with that array
      .email({
        minDomainSegments: 2,
        tlds: { allow: ["com", "net", "org", "edu"] },
      })
      .required()
      .label("Email"),

    // Require 8 characters + at least 1 upper, 1 lower, and 1 number
    password: Joi.string()
      .trim()
      .pattern(
        new RegExp("^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$")
      )
      .required()
      .label("Password")
      .messages({
        "string.pattern.base":
          "Password must contain: minimum 8 characters, 1 upper case, 1 lower case, 1 number",
      }),

    confirm_password: Joi.string()
      .valid(Joi.ref("password"))
      .required()
      .label("Confirm password")
      .messages({ "any.only": "Passwords do not match" }),
  }).with("password", "confirm_password");

  doSubmit = async (user) => {
    console.log("user in doSubmit: ", user);

    try {
      const { data: response } = await RESTApiService.post(`register`, {
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        password: user.password,
      });
      toast.success("Registration successful");

      const { navigate } = this.props;
      navigate("/login");
    } catch (exception) {
      // If the user already exists, pop a toast error and set the JOI error message for the form
      if (exception.response && exception.response.status === 409) {
        toast.error(`User with email address ${user.email} already exists.`, {
          position: "bottom-left",
        });

        const errors = { ...this.state.errors }; // Get the errors state so we can update it with the duplicate error
        errors.email = exception.response.data; // Grab the email address from the response
        this.setState({ errors });
      }
    }
  };

  render() {
    return (
      // Had to set style width so form did not shrink during resizing and become unusable
      <div className="container-fluid pt-xxl-5" style={{ width: 340 }}>
        {/*Every form has an onSubmit function to submit the form data*/}
        <form onSubmit={this.handleSubmit} className="pt-2">
          <h1>Register</h1>
          {this.renderInput("firstName", "First Name", "autoFocus")}
          {this.renderInput("lastName", "Last Name")}
          {this.renderInput("email", "Email Address", "", "email")}
          {this.renderInput("password", "Password", "", "password")}
          {this.renderInput(
            "confirm_password",
            "Confirm Password",
            "",
            "password"
          )}

          {this.renderButton("Register")}
        </form>
      </div>
    );
  }
}

export default withRouter(RegistrationForm);
