import React, { useEffect, useMemo, useState } from "react";
import { Button, ButtonGroup, Container } from "reactstrap";
import {
  useFlexLayout,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { useNavigate } from "react-router-dom";
import { FilterBar } from "./common/FilterBar";
import RESTApi from "../services/RESTApiService";
import { toast } from "react-toastify";
import Confirmation from "./common/Confirmation.jsx";
import MyModal from "./MyModal";
import * as Utilities from "./common/Utilities";
import MyTable from "./common/MyTable";

export function LopeList() {
  // For navigating to other pages
  const navigate = useNavigate();
  // To send state with the route change, use {state: parameter} and access in child using useLocation
  const routeChange = (url, lopes) => {
    navigate(url, { state: lopes });
  };

  // Creates an array "lopes" with a blank initial state that will contain the lopes
  // setLopes is the function to update the value of the lopes state
  const [lopes, setLopes] = useState([]);

  // Handles the state of the Modal for editing or adding Lopes
  const [modalShow, setModalShow] = useState(false);

  // Handles the population of the Modal props for adding or editing Lopes
  const [modalProps, setModalProps] = useState({});

  // Provides the total of all lopes for the Lopes page top right Total Balance.
  function calculateTotalBalance() {
    const total =
      lopes.length > 0
        ? lopes.reduce((total, lope) => total + lope.balance, 0)
        : 0;
    //console.log("Total is showing ", Number(total));
    return Utilities.toCurrency(Number(total));
  }

  // Gets the lopes from the database.
  const fetchLopes = async () => {
    const { data: lopes } = await RESTApi.get(`lope`);

    // Updates the array with the response from the API
    setLopes(lopes);
  };

  // Deletes a lope. Note the URL is a string concatenation of the URL plus the passed lopeID forming the REST API url
  const handleDeleteLope = async (lopeToDelete) => {
    // Grab the current state for restoration if needed
    const prevState = lopes;

    // Filter the currLopes array and exclude the deletedLope
    const currLopes = lopes.filter(
      (lope) => lope.lopeID !== lopeToDelete.lopeID
    );
    // Set the state to the updated currLopes array
    setLopes(currLopes);

    // Handle the delete.
    try {
      await RESTApi.delete(`lope/${lopeToDelete.lopeID}`);

      // Pop a toast
      toast.success(`${lopeToDelete.name} deleted successfully`, {
        position: "bottom-left",
      });
    } catch (exception) {
      // If there is an exception with a 404, the Lope wasn't found, alert the user.
      if (exception.response && exception.response.status === 404) {
        toast.error(`${lopeToDelete.name} has already been deleted.`);
        // If we have a 404, fetch the lopes again to get the correct data
        await fetchLopes();
        // Exit the function immediately
        return;
      }

      // Set the state back to prevState because there was an error deleting the lope
      setLopes(prevState);
    }
  };

  // Adds the new lope to the database using async await and axios
  const handleAddLope = async (lope) => {
    try {
      const { status, data: newLope } = await RESTApi.post(`lope`, {
        lope: lope,
      });

      const updatedLopes = [...lopes, newLope];
      setLopes(updatedLopes);

      toast.success(`Added "${lope.name}" successfully.`, {
        position: "bottom-left",
      });

      return { status, lope: newLope };
    } catch (exception) {
      if (exception.response && exception.response.status === 409) {
        toast.error("Lope already exists.");
      }
    }
  };

  // Handles the CSV import process and updated the state with the returned Lope (if successful)
  const handleCSVImport = async (file, lope) => {
    // Copy the current state and update the state to reflect the import
    try {
      let formData = new FormData();
      formData.append("file", file);
      formData.append("lope", lope.lopeID);

      const { status, data: updatedLope } = await RESTApi.post(
        `lope/uploadFile`,
        formData
      );

      // Update the lopes state
      const currLopes = [...lopes];

      // Get the index of the updated lope in the copy of lopes
      const indexOfLope = currLopes.findIndex(
        (lope) => lope.lopeID === updatedLope.lopeID
      );

      // Change the currLopes array at index [indexOfLope] to be the returned lope
      currLopes[indexOfLope] = {
        ...currLopes[indexOfLope],
        balance: updatedLope.balance,
      };

      // Set the state to the updated currLopes array
      setLopes(currLopes);

      toast.success(`Transactions imported into "${lope.name}" successfully.`, {
        position: "bottom-left",
      });

      // Return status as an object since that is how we are handling it in doSubmit
      return { status };
    } catch (exception) {
      toast.error(
        `CSV import failed, please check your CSV file and try to import again in "${lope.name}" Transactions area.`
      );
    }
  };

  // Updates the lope in the database using async await and axios
  const handleUpdateLope = async (updatedLope) => {
    const prevState = lopes;

    // Update the lopes state
    const currLopes = [...lopes];
    // Get the index of the updated lope in the copy of lopes
    const indexOfLope = currLopes.findIndex(
      (lope) => lope.lopeID === updatedLope.lopeID
    );

    // Change the currLopes array at index [indexOfLope] to be the returned lope
    currLopes[indexOfLope] = {
      ...currLopes[indexOfLope],
      name: updatedLope.name,
      monthlyFundAmount: updatedLope.monthlyFundAmount,
    };

    // Set the state to the updated currLopes array
    setLopes(currLopes);

    try {
      const { status, lope } = await RESTApi.put(
        `lope/${updatedLope.lopeID}`,
        updatedLope
      );

      toast.success(`${updatedLope.name} updated successfully.`, {
        position: "bottom-left",
      });

      return { status, lope };
    } catch (exception) {
      if (exception.response && exception.response.status === 404) {
        toast.error("Lope not found. This may be because it was deleted.");
      }
      if (exception.response && exception.response.status === 409) {
        toast.error("Lope already exists.");
      }

      // Revert to lope state prior to change
      setLopes(prevState);
    }
  };

  const handleFundLopes = async (lopeList) => {
    // Get the current date in ISO format and slice off the date portion from the time
    const now = new Date().toISOString().slice(0, 10);
    // Array for transaction batch
    let transactionBatch = [];

    // Copy the state of lopes
    const currLopes = [...lopes];
    // Fund any lope contained in lopeList that matches a lope in lopes
    currLopes.forEach((lope) => {
      if (lopeList.find((lopeToFund) => lopeToFund.lopeID === lope.lopeID)) {
        lope.balance += Number(lope.monthlyFundAmount);
      }
    });

    // Update the lopes state with the updated balance
    setLopes(currLopes);

    try {
      // Build a transaction and save it to the transactionBatch array
      for (let i = 0; i < lopeList.length; i++) {
        let transaction = {
          lope: {
            lopeID: lopeList[i].lopeID,
            name: lopeList[i].name,
          },
          date: now,
          description: "Fund",
          amount: lopeList[i].monthlyFundAmount,
        };

        transactionBatch = [...transactionBatch, { ...transaction }];
        // console.log("Transaction batch is now ", transactionBatch);
      }

      await RESTApi.post(`fund`, transactionBatch);

      toast.success(
        transactionBatch.length > 1
          ? `All lopes funded.`
          : `${transactionBatch[0].lope.name} funded successfully.`,
        { position: "bottom-left" }
      );
    } catch (exception) {
      console.log(
        "encountered an error funding lopes, reverting fund transactions."
      );

      toast.error(
        "There was an error funding lopes. No changes have been made."
      );

      // Something isn't right so fetch lopes again to update the UI
      await fetchLopes();
    }
  };

  // Memoize the lopes
  const lopeData = useMemo(() => [...lopes], [lopes]);

  // Set up the default props of the columns for when we don't have any set widths or other props
  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 30,
      width: 200,
      maxWidth: 400,
    }),
    []
  );

  // Set the columns of the table manually
  // Note that we have a dependency of [lopes] in here because we need to have the current state of lopes since useMemo ignores state changes
  const lopeColumns = useMemo(
    () => [
      {
        Header: "Name",
        id: "name",
        accessor: "name",
      },
      {
        Header: "Monthly Fund Amount",
        accessor: "monthlyFundAmount",
        sortType: "basic",
        align: "right",
        // Format the cell as currency using the toCurrency function defined
        Cell: (props) => <div>{Utilities.toCurrency(props.value)} </div>,
      },
      {
        Header: "Balance",
        accessor: "balance",
        sortType: "basic",
        width: 70,
        align: "right",
        // Format the cell as currency using the toCurrency function defined
        Cell: (props) => <div> {Utilities.toCurrency(props.value)} </div>,
      },
      {
        Header: "Actions",
        width: 120,
        canSort: false,
        Cell: ({ row }) => (
          <div>
            <ButtonGroup>
              <Button
                size="sm"
                color="success"
                onClick={() => handleFundLopes([row.original])}
              >
                Fund
              </Button>
              <Button
                id={row.index}
                size="sm"
                type="submit"
                color="warning"
                onClick={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                  setModalProps({
                    buttonLabel: "Edit",
                    lopeToEdit: row.original.lopeID,
                  });
                  setModalShow(true);
                }}
              >
                Edit
              </Button>
              <Confirmation
                lopeToDelete={row.original}
                onDeleteLope={handleDeleteLope}
              />
              <Button
                size="sm"
                color="info"
                onClick={() =>
                  routeChange(`${row.original.lopeID}/transactions`, { lopes })
                }
              >
                Transactions
              </Button>
            </ButtonGroup>
          </div>
        ),
      },
    ],
    [lopes, modalShow]
  );

  // Create a table instance with useTable
  const tableInstance = useTable(
    {
      columns: lopeColumns,
      defaultColumn,
      data: lopeData,
      sortTypes: {
        // Custom sort for case-insensitive alpha sorting. Default sorting for react-table v7 is case-sensitive
        // Simply provide your own functions here for custom sorting based on the data type alphanumeric, datetime, basic, number
        alphanumeric: (row1, row2, columnName) => {
          const row1Lower = row1.values[columnName].toLowerCase();
          const row2Lower = row2.values[columnName].toLowerCase();

          // Compare the strings and return values based on lowercase alpha
          if (row1Lower < row2Lower) return -1;
          else if (row1Lower > row2Lower) return 1;
          else return 0;
        },
      },
      // Set the default sort order by name and ascending
      initialState: { sortBy: [{ id: "name", desc: false }] },
    },
    useFlexLayout,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  // Destruct the table instance into usable props
  const { preGlobalFilteredRows, setGlobalFilter, state } = tableInstance;

  //Fetches the lopes using the fetchLopes function
  useEffect(() => {
    fetchLopes();
  }, []);

  return (
    <div className="LopeList">
      {/*The Modal for editing an adding Lopes*/}
      <MyModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        onAddLope={handleAddLope}
        onUpdateLope={handleUpdateLope}
        onImportCSV={handleCSVImport}
        {...modalProps}
      />
      {/*End Modal*/}
      <Container fluid className="mt-2">
        <div className="p-2">
          <div className="row g-2 justify-content-start">
            <div className="col-auto">
              <Button
                type="submit"
                color="success"
                onClick={() => {
                  setModalProps({ buttonLabel: "Add Lope" });
                  setModalShow(true);
                }}
              >
                Add Lope
              </Button>
            </div>
            <div className="col">
              <Button
                type="submit"
                color="success"
                onClick={() => handleFundLopes(lopes)}
              >
                Fund All
              </Button>
            </div>
            <div className="col p-0">
              <div className="btn float-end">
                <FilterBar
                  preGlobalFilteredRows={preGlobalFilteredRows}
                  setGlobalFilter={setGlobalFilter}
                  globalFilter={state.globalFilter}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <h3 className="col-auto me-auto">Lopes</h3>
          <h3 className="col-auto ms-auto me-2">
            Total Balance:&ensp;
            {calculateTotalBalance()}
          </h3>
        </div>
        {/*The MyTable component*/}
        <div className="card p-2 mt-2 shadow">
          <MyTable tableInstance={tableInstance} />
        </div>
      </Container>
    </div>
  );
}
