import React from "react";
import Joi from "joi";
import { Modal } from "react-bootstrap";
import Form from "./common/Form";
import { Button } from "reactstrap";
import { noop } from "bootstrap/js/src/util";
import RESTApiService from "../services/RESTApiService";

class TransactionEdit extends Form {
  state = {
    data: {
      transactionID: "",
      date: "",
      description: "",
      amount: 0,
      lope: {},
    },
    errors: {},
  };

  async componentDidMount() {
    // Destructure the props to get the needed values
    const { transactionToEdit, buttonLabel } = this.props;

    // End here if we are adding a transaction
    if (buttonLabel === "Add Transaction") return;

    // Get specifics from the transactionToEdit
    const {
      transactionID,
      lope: { lopeID },
    } = transactionToEdit;

    // Grab the transaction from the backend
    const { data: transaction } = await RESTApiService.get(
      `${lopeID}/transaction/${transactionID}`
    );

    // Set the state with the returned data
    this.setState({ data: transaction });
  }

  // The schema for Joi validation
  schema = Joi.object({
    transactionID: Joi.any().label("Transaction ID"),
    date: Joi.string().required().label("Date"),
    description: Joi.string().trim().label("Description"),
    amount: Joi.number().required().label("Amount"),
    lope: Joi.object().label("Lope"),
  });

  doSubmit = async (transaction) => {
    let result;

    if (transaction.transactionID === "")
      result = await this.props.onAddTransaction(transaction);
    else result = await this.props.onUpdateTransaction(transaction);

    result === 200 ? this.props.onHide() : noop();
  };

  render() {
    const header = this.props.buttonLabel;

    return (
      <div className="container-fluid">
        <form onSubmit={this.handleSubmit} className="p-4">
          {this.renderInput("date", "Date", "autoFocus", "date")}
          {this.renderInput("description", "Description", "")}
          {this.renderInput("amount", "Amount", "", "number")}
        </form>
        <Modal.Footer>
          {this.renderButton(
            header === "Edit" ? "Save Changes" : "Add Transaction"
          )}
          <Button
            className="btn btn-primary mt-3"
            color="secondary"
            onClick={this.props.onHide}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </div>
    );
  }
}

export default TransactionEdit;
