import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";

const Confirmation = (props) => {
  let [isShowingModal, setShowingModal] = useState(false);

  let toggle = () => {
    setShowingModal(!isShowingModal);
  };

  return (
    <>
      <Button size="sm" type="submit" variant="danger" onClick={toggle}>
        Delete
      </Button>
      <Modal show={isShowingModal} onHide={toggle}>
        <Modal.Header closeButton>
          <Modal.Title id="modal-title">Confirm Deletion</Modal.Title>
        </Modal.Header>
        <div className="container-fluid">
          <form onSubmit={props.onDeleteLope} className="p-4">
            <h4>Are you sure you want to delete {props.lopeToDelete.name}?</h4>
            <p>
              This is not reversible and will delete all transactions associated
              with {props.lopeToDelete.name}.
            </p>
          </form>
          <Modal.Footer>
            <Button
              className="btn btn-primary mt-3"
              variant="danger"
              onClick={() => props.onDeleteLope(props.lopeToDelete)}
            >
              Delete
            </Button>
            <Button
              className="btn btn-primary mt-3"
              variant="secondary"
              onClick={toggle}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
};

export default Confirmation;
