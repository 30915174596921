import React, { Component } from "react";
import { ToastContainer } from "react-toastify";
import { Route, Routes } from "react-router-dom";
import LoginForm from "./components/LoginForm";
import RegistrationForm from "./components/RegistrationForm";
import AppNavbar from "./components/common/AppNavBar";
import { LopeList } from "./components/LopeList";
import LopeEdit from "./components/LopeEdit";
import { NotFound } from "./components/NotFound";
import { TransactionList } from "./components/TransactionList";
import Logout from "./components/Logout";
import authentication from "./services/authenticationService";
import { ProtectedRoute } from "./components/common/ProtectedRoute";
import "react-toastify/dist/ReactToastify.min.css";
import "./App.css";

class App extends Component {
  // State for storing the currently logged-in user
  state = {};

  // Get the currently logged-in user from local storage
  componentDidMount() {
    const user = authentication.getLoggedInUser();
    this.setState({ user });
  }

  render() {
    // Grab the user from state
    const { user } = this.state;

    return (
      <React.Fragment>
        <ToastContainer position="top-center" theme="colored" />
        <AppNavbar user={user} />
        <main className="container-fluid px-0">
          <Routes>
            <Route exact path="/login" element={<LoginForm />} />
            <Route exact path="/register" element={<RegistrationForm />} />

            {/*Place all protected routes for USERS in this container. Specify "requiredPermissions" based on */}
            {/*user permissions such as checking if a user is an Admin for access to the */}
            {/*admin page.*/}
            <Route element={<ProtectedRoute requiredPermissions={"USER"} />}>
              <Route exact path="/" element={<LopeList user={user} />} />
              <Route exact path="/lopes" element={<LopeList user={user} />} />
              <Route
                exact
                path="/LopeEdit"
                element={<LopeEdit user={user} />}
              />
              {/* The :lopeID allows for the variable to be passed in the URL */}
              <Route
                exact
                path="/lopes/:lopeID/transactions"
                element={<TransactionList user={user} />}
              />
            </Route>
            <Route exact path="/logout" element={<Logout />} />
            {/* Redirects all unknown paths to the NotFound component */}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </main>
      </React.Fragment>
    );
  }
}

export default App;
