import React from "react";
import Joi from "joi";
import { Modal } from "react-bootstrap";
import Form from "./common/Form";
import { Button, Label } from "reactstrap";
import { noop } from "bootstrap/js/src/util";

class FundsTransferModal extends Form {
  state = {
    data: {
      fromLopeID: "",
      toLopeID: "",
      amount: 0,
    },
    errors: {},
  };

  async componentDidMount() {
    // Destructure the props to get the needed values
    const { lopeID } = this.props.fromLope;

    // Set the state with the lope transfer was initiated from
    this.setState({
      data: { ...this.state.data, fromLopeID: String(lopeID) },
    });
  }

  // The schema for Joi validation
  schema = Joi.object({
    amount: Joi.number()
      .min(0.01)
      // Only allow a transfer that doesn't make the fromLope negative
      .max(this.props.fromLope.balance)
      .required()
      .label("Amount")
      .messages({
        "number.max":
          "Amount cannot be negative, or exceed the balance the transfer is initiated from.",
      }),

    toLopeID: Joi.string().required().label("To"),
    fromLopeID: Joi.string().required().label("From"),
  });

  // Handles the Lope Selection menu changes
  handleSelectChange = (e) => {
    // Set the state with the currently selected lope to transfer to
    this.setState({ data: { ...this.state.data, toLopeID: e.target.value } });
  };

  doSubmit = async (transferRequest) => {
    let result;

    result = await this.props.onTransferFunds(transferRequest);

    result === 200 ? this.props.onHide() : noop();
  };

  render() {
    const header = this.props.buttonLabel;

    return (
      <div className="container-fluid">
        <form onSubmit={this.handleSubmit} className="p-4">
          <Label for="fromLope">From: </Label>
          <select
            name="fromLope"
            className="form-select mb-4"
            id="fromLope"
            disabled
          >
            <option>{this.props.fromLope.name}</option>
          </select>
          <Label for="toLope">To: </Label>
          {/*The Lope Selection Menu*/}
          <select
            name="toLope"
            className="form-select mb-4"
            defaultValue="defaultOption"
            onChange={(selected) => this.handleSelectChange(selected)}
            aria-label="Transfer To Lope Selection"
          >
            Add a blank option to require a user to
            <option value="defaultOption" disabled>
              Select a Lope to transfer to
            </option>
            {/*Map the Lopes to options*/}
            {this.props.lopes.map((lope) => (
              <option value={lope.lopeID} key={lope.lopeID}>
                {lope.name}
              </option>
            ))}
          </select>
          {this.renderInput("amount", "Amount", "", "number")}
        </form>
        <Modal.Footer>
          {this.renderButton(header)}
          <Button
            className="btn btn-primary mt-3"
            color="secondary"
            onClick={this.props.onHide}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </div>
    );
  }
}

export default FundsTransferModal;
