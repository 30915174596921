import React, { useState, useEffect, useMemo } from "react";
import { Button, ButtonGroup, Container } from "reactstrap";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
  useFlexLayout,
} from "react-table";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import RESTApiService from "../services/RESTApiService";
import { FilterBar } from "./common/FilterBar";
import { toast } from "react-toastify";
import { LopeDetails } from "./LopeDetails";
import MyModal from "./MyModal";
import MyTable from "./common/MyTable";
import * as Utilities from "./common/Utilities";

export function TransactionList() {
  // Grab the lopeID from the react router using useParams
  const { lopeID } = useParams();
  // Grab the lopes from react router
  const {
    state: { lopes },
  } = useLocation();
  const location = useLocation();
  // Constant for the useNavigate object
  const navigate = useNavigate();
  const routeChange = (url, lopes) => {
    /* replace is set to true here so that we don't push the selection onto the history stack. So, when back button
     is pressed, it goes back to the expected Lopes page and not the prior lope */
    navigate(url, { replace: true, state: lopes });
  };

  // The state of the transactions
  const [transactions, setTransactions] = useState([]);

  // Handles the state of the Modal for editing or adding Lopes
  const [modalShow, setModalShow] = useState(false);

  // Handles the population of the Modal props for adding or editing Lopes
  const [modalProps, setModalProps] = useState({});

  // Gets the transactions from the database.
  const fetchTransactions = async () => {
    const { data: transactions } = await RESTApiService.get(
      `${lopeID}/transaction`
    );
    // Updates the array with the response from the API
    setTransactions(transactions);
  };

  // Handles the Lope Selection menu changes
  const handleSelectChange = (e) => {
    // console.log("Selection has been changed", e.target.value);
    routeChange(`/lopes/${e.target.value}/transactions`, { lopes });
  };

  // Adds the new transaction to the database using async await and axios
  const handleAddTransaction = async (transaction) => {
    // Add the lopeID to the transaction for sending to API
    transaction = { ...transaction, lope: { lopeID: lopeID } };

    try {
      const { status, data: newTransaction } = await RESTApiService.post(
        `${lopeID}/transaction`,
        [transaction]
      );

      // Update the transactions state
      const updatedTransactions = [...transactions, ...newTransaction];
      setTransactions(updatedTransactions);

      // Pop a toast
      toast.success("Transaction added successfully.", {
        position: "bottom-left",
      });

      return status;
    } catch (exception) {
      toast.error("Unable to save the transaction. Please try again.");
    }
  };

  // Deletes a transaction
  const handleDeleteTransaction = async (transactionToDelete) => {
    // Get the current transaction state
    const prevState = transactions;

    // Remove the transaction being deleted from the transactions array
    const currTransactions = transactions.filter(
      (transaction) =>
        transaction.transactionID !== transactionToDelete.transactionID
    );

    // Update the transactions
    setTransactions(currTransactions);

    // Call the server and handle the errors
    try {
      await RESTApiService.delete(
        `${lopeID}/transaction/${transactionToDelete.transactionID}`
      );

      // Pop a toast
      toast.success("Transaction deleted successfully.", {
        position: "bottom-left",
      });
    } catch (exception) {
      // Unexpected exceptions are handled in the RESTApiService

      // If the exception is a 404, pop a toast to notify the user
      if (exception.response && exception.response.status === 404) {
        toast.error(
          `Transaction with ID ${transactionToDelete.transactionID} doesn't exist.`
        );
        // If we have a 404, fetch the lopes again to get the correct data
        await fetchTransactions();
        // Exit the function immediately
        return;
      }
      // Revert to previous state
      setTransactions(prevState);
    }
  };

  // Updates a transaction
  const handleUpdateTransaction = async (updatedTransaction) => {
    // Grab the current state
    const prevState = transactions;

    // Copy transactions state to update
    const currTransactions = [...transactions];

    // Grab the index of the transactionToUpdate
    const indexOfTransaction = currTransactions.findIndex(
      (transaction) =>
        transaction.transactionID === updatedTransaction.transactionID
    );

    // Update the transaction
    currTransactions[indexOfTransaction] = { ...updatedTransaction };
    // Set the state
    setTransactions(currTransactions);

    // Call the API and post the updates
    try {
      const { status } = await RESTApiService.put(
        `${lopeID}/transaction/${updatedTransaction.transactionID}`,
        updatedTransaction
      );

      // Pop a toast
      toast.success(`Transaction updated successfully.`, {
        position: "bottom-left",
      });

      return status;
    } catch (exception) {
      if (exception.response && exception.response.status === 404) {
        toast.error(
          "Transaction not found. This may be because it was deleted."
        );
      }

      // Set the state to the previous state
      setTransactions(prevState);
    }
  };

  const handleTransferFunds = async (transferRequest) => {
    try {
      const { status, data: transferResult } = await RESTApiService.post(
        `${lopeID}/transaction/transfer`,
        transferRequest
      );

      // Only update the current Lope's transactions by stripping off the other
      const transferResultTrimmed = transferResult.filter(
        (transaction) => transaction.lope.lopeID === Number(lopeID)
      );

      // Update the transactions state
      const updatedTransactions = [...transactions, ...transferResultTrimmed];
      setTransactions(updatedTransactions);

      // Pop a toast
      toast.success("Transfer completely successfully.", {
        position: "bottom-left",
      });

      return status;
    } catch (exception) {
      toast.error("Unable to transfer funds. Please try again.");
    }
  };

  // Get all the transactions
  const transactionData = useMemo(() => [...transactions], [transactions]);

  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 30,
      width: 200,
      maxWidth: 400,
    }),
    []
  );

  // Set the columns of the table manually
  const transactionColumns = useMemo(
    () => [
      {
        Header: "Date",
        id: "date",
        accessor: "date",
        sortDescFirst: "true",
        width: 30,
      },
      {
        Header: "Description",
        accessor: "description",
      },
      {
        Header: "Amount",
        accessor: "amount",
        width: 40,
        align: "right",
        // Format the cell as currency using the toCurrency function defined
        // If value is < 0 format it as red using text-danger
        Cell: ({ value }) => (
          <div className={value < 0 ? "text-danger" : ""}>
            {Utilities.toCurrency(value)}
          </div>
        ),
      },
      {
        Header: "Actions",
        width: 40,
        canSort: false,
        Cell: ({ row }) => (
          <div>
            <ButtonGroup>
              <Button
                size="sm"
                type="submit"
                color="warning"
                onClick={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                  setModalProps({
                    modalType: "TransactionEdit",
                    buttonLabel: "Edit",
                    transactionToEdit: row.original,
                    onUpdateTransaction: handleUpdateTransaction,
                  });
                  setModalShow(true);
                }}
              >
                Edit
              </Button>
              <Button
                size="sm"
                color="danger"
                onClick={() => handleDeleteTransaction(row.original)}
              >
                Delete
              </Button>
            </ButtonGroup>
          </div>
        ),
      },
    ],
    [transactions, modalShow]
  );

  // Create the table instance and configure it
  const tableInstance = useTable(
    {
      columns: transactionColumns,
      data: transactionData,
      defaultColumn,

      // Set the default sort order for date and descending
      initialState: {
        sortBy: [{ id: "date", desc: true }],
      },
    },
    useFlexLayout,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { globalFilter },
  } = tableInstance;

  // Fetches the transactions using the fetchTransactions function - Should come after any consts
  // Setting the dependency for location.key allows the table data to refresh when the selection is changed and the route
  // goes to another lope
  useEffect(async () => {
    await fetchTransactions();
  }, [location.key]);

  return (
    <div className="TransactionList">
      {/*The Modal for editing an adding Lopes*/}
      <MyModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        {...modalProps}
      />
      {/*End Modal*/}
      <Container fluid className="mt-2">
        <div className="float-right p-2 row g-2 justify-content-start">
          <div className="col-auto">
            <Button
              type={"button"}
              onClick={() => {
                navigate(-1);
              }}
            >
              Back
            </Button>
          </div>
          <div className="col-auto">
            <Button
              type="submit"
              color="success"
              onClick={() => {
                setModalProps({
                  modalType: "TransactionEdit",
                  buttonLabel: "Add Transaction",
                  onAddTransaction: handleAddTransaction,
                });
                setModalShow(true);
              }}
            >
              Add Transaction
            </Button>
          </div>
          <div className="col-auto">
            <Button
              type="submit"
              color="success"
              onClick={() => {
                setModalProps({
                  modalType: "TransferFunds",
                  buttonLabel: "Transfer Funds",
                  // Send the current lope to the FundsTransferModal
                  fromLope: lopes.find(
                    (lope) => lope.lopeID === Number(lopeID)
                  ),
                  // Send the filtered set of lopes that excludes the current lope
                  lopes: lopes.filter((lope) => lope.lopeID !== Number(lopeID)),
                  onTransferFunds: handleTransferFunds,
                });
                setModalShow(true);
              }}
            >
              Transfer Funds
            </Button>
          </div>
          <div className="col p-0">
            <div className="btn float-end">
              <FilterBar
                preGlobalFilteredRows={preGlobalFilteredRows}
                setGlobalFilter={setGlobalFilter}
                globalFilter={globalFilter}
              />
            </div>
          </div>
        </div>
        {/*The Lope Selection Menu*/}
        <select
          className="form-select mb-2 w-25"
          // Sets the default option by using the lopeID passed
          defaultValue={lopeID}
          onChange={(selected) => handleSelectChange(selected)}
          aria-label="Lope Selection"
        >
          {/*Map the Lopes to options*/}
          {lopes.map((lope) => (
            <option value={lope.lopeID} key={lope.lopeID}>
              {lope.name}
            </option>
          ))}
        </select>
        {/*The Lope Details component*/}
        <LopeDetails
          lope={lopes.find(
            (lopeInArray) => lopeInArray.lopeID === Number(lopeID)
          )}
        />
        {/*The MyTable component*/}
        <span className="card p-2 mt-2 shadow">
          <h5 className="float-start">
            <strong>Transactions</strong>
          </h5>
          <MyTable tableInstance={tableInstance} />
        </span>
      </Container>
    </div>
  );
}
