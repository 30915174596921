import React from "react";

export const NotFound = () => {
  return (
    <React.Fragment>
      <div className="pt-5 text-center">
        <h1>Ooops....</h1>
        <h3>That page doesn't exist, please check the URL.</h3>
      </div>
    </React.Fragment>
  );
};
