import React from "react";
import Joi from "joi";
import Form from "./common/Form";
import authentication from "../services/authenticationService";
import { toast } from "react-toastify";

class LoginForm extends Form {
  state = {
    data: {
      email: "",
      password: "",
    },

    errors: {},
  };

  // The schema for Joi validation
  schema = Joi.object({
    email: Joi.string()
      // TODO: get a list of TLD's and replace the explicit entries here with that array
      .email({
        minDomainSegments: 2,
        tlds: { allow: ["com", "net", "org", "edu"] },
      })
      .required()
      .label("Email"),

    // Until password is 8 characters long, login button is disabled
    password: Joi.string().min(8).required().label("Password"),
  });

  doSubmit = async () => {
    const { email, password } = this.state.data;
    try {
      await authentication.login(email, password);

      // Send the user to the home page now that they are logged in
      window.location = "/lopes";
    } catch (exception) {
      // If we have bad credentials notify the user
      if (exception.response && exception.response.status === 401)
        toast.error("Email or password is invalid.");
    }
  };

  render() {
    return (
      // Had to set style width so form did not shrink during resizing and become unusable
      <div
        className="container-fluid mt-5 align-items-center"
        style={{ width: 340 }}
      >
        <h3 className="text-center mb-4">Log in to your account</h3>
        {/*Every form has an onSubmit function to submit the form data*/}
        <form onSubmit={this.handleSubmit} className="pt-3">
          {this.renderInput("email", "Email", "autoFocus", "email")}
          {this.renderInput("password", "Password", "", "password")}
          {this.renderButton("Log In", "w-100 btn-lg")}
        </form>
      </div>
    );
  }
}

export default LoginForm;
